import { useState } from 'react';

function isSameSeat(a, b) {
  return a && b && a.row === b.row && a.number === b.number;
}

function Seatmap({ update }) {
  const [currentSeat, setCurrentSeat] = useState(null);

  const seats = [];

  // You would fetch this from a database.
  for (let row = 1; row <= 5; row++) {
    const rowSeats = [];
    for (let number = 1; number <= 8; number++) {
      rowSeats[number - 1] = { row, number };
    }
    seats[row - 1] = rowSeats;
  }

  return (
    <div className="flex flex-col justify-around bg-white shadow-xl">
      {seats.reverse().map(row => {
        return (
          <div key={`${row[0].row}`} className="flex flex-row justify-around">
            {row.map(seat => {
              return (
                <div
                  key={`${seat.row}-${seat.number}`}
                  className={
                    'text-center text-xl p-2 md:text-2xl md:p-8 lg:p-4 lg:text-2xl cursor-pointer hover:text-green-700 ' +
                    (isSameSeat(currentSeat, seat)
                      ? 'text-blue'
                      : 'text-green-500')
                  }
                  onClick={() => {
                    setCurrentSeat(seat);
                    update(seat);
                  }}
                >
                  <i className="fas fa-loveseat"></i>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Seatmap;
