import * as d3 from 'd3';
import uniqid from 'uniqid';
import { useEffect } from 'react';

function resizeCanvas({ data, id, flip }) {
  var canvas = document.getElementById(id);
  if (!canvas) return;
  canvas.width = window.innerWidth + 1;
  canvas.height = window.innerHeight / 2;

  const ctx = canvas.getContext('2d');
  const height = canvas.height;
  const width = canvas.width;

  if (flip) {
    ctx.translate(width, 0);
    ctx.scale(-1, 1);
  }

  draw({ ctx, height, width, data });
}

function draw({ ctx, width, height, data, flip }) {
  const walkX = d3
    .scaleLinear()
    .domain([0, 20])
    .range([0, width + 5]);

  const walkY = d3
    .scaleLinear()
    .domain([0, 4])
    .range([200 - 10, 10]);

  const area = d3
    .area()
    .x(d => walkX(d.step))
    .y0(d => walkY(d.value * -1))
    .y1(d => walkY(d.value))
    .context(ctx);

  ctx.beginPath();
  ctx.fillStyle = '#c4edff';
  area(data);
  ctx.fill();
}

function Header({ flip }) {
  const id = uniqid('shape-');

  useEffect(() => {
    const data = [];
    for (let i = 0, v = 1; i < 50; ++i) {
      v += 0.2;
      data.push({ step: i, value: v });
    }

    window.addEventListener(
      'resize',
      function() {
        resizeCanvas({ data, id, flip });
      },
      false
    );

    resizeCanvas({ data, id, flip });
  });

  return <canvas id={id}></canvas>;
}

export default Header;
