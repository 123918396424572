import { Link } from 'react-router-dom';
import PageLayout from '@/components/page-layout';

function SuccessPage() {
  return (
    <PageLayout>
      <div className="flex flex-col w-full rounded-lg bg-white overflow-hidden shadow-lg text-2xl md:text-3xl text-white">
        <div className="flex w-full h-96 justify-center items-center bg-blue-800 text-center px-12">
          Thanks for ordering your movie tickets with us!
          <br />
          <br />
          We'll email you shortly with the booking refrence.
        </div>

        <Link to="/">
          <button
            className="flex w-full h-24 bg-blue-400 font-bold justify-center items-center hover:bg-blue-600"
            type="button"
          >
            Browse more movie tickets!
          </button>
        </Link>
      </div>
    </PageLayout>
  );
}

export default SuccessPage;
