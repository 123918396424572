import { tsv } from 'd3-fetch';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PageLayout from '@/components/page-layout';
import Seatmap from '@/components/seatmap';
import React from 'react';
import Drawer from '@material-ui/core/Drawer';

function SelectSeatPage() {
  const [title, setTitle] = useState({});
  const [seat, setSeat] = useState({});
  const { id } = useParams();

  // This would naturally be fetching the title by id from endpoint / cached store.
  useEffect(() => {
    tsv('/titles/basic.tsv').then(res => {
      setTitle(res.find(title => title.tconst === id));
    });
  }, [id]);

  return (
    <>
      <PageLayout>
        <h1 className="font-bold text-2xl mb-8">
          Please select a seat for {title.primaryTitle}
        </h1>
        <Seatmap
          update={seat => {
            setSeat(seat);
          }}
        />
      </PageLayout>

      <React.Fragment>
        <Drawer
          anchor="bottom"
          open={JSON.stringify(seat) !== '{}' ? true : false}
          onClose={() => {
            setSeat({});
          }}
        >
          <div className="flex bg-white w-full h-28 items-center">
            <PageLayout>
              <div className="flex justify-between items-center">
                <h1 className="flex font-bold flex-col md:flex-row md:text-3xl">
                  <div className="block truncate">{title.primaryTitle}</div>
                  <div className="hidden md:block px-4">-</div>
                  <div>Row {seat.row}</div>
                  <div className="hidden md:block px-4">-</div>
                  <div>Seat {seat.number}</div>
                </h1>
                <Link to="/success">
                  <button
                    className="text-2xl font-bold bg-blue-400 rounded-full px-12 py-2 text-white hover:shadow-lg hover:bg-blue-600"
                    type="button"
                  >
                    BUY
                  </button>
                </Link>
              </div>
            </PageLayout>
          </div>
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default SelectSeatPage;
