import TitleListItem from '@/components/title-list-item';

function TitleList({ titles }) {
  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {titles.map(title => (
        <TitleListItem title={title} key={title.tconst} />
      ))}
    </ul>
  );
}

export default TitleList;
