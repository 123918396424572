import { Link } from 'react-router-dom';

function TitleListItem({ title }) {
  return (
    <Link to={`/${title.tconst}/seatmap`}>
      <li className="flex relative pb-16/9 rounded-2xl overflow-hidden shadow-md transform hover:shadow-xl hover:scale-103 transition-transform duration-100">
        <img
          className="absolute w-full h-full z-1"
          alt="Movie cover"
          src="jpg/popcorn.jpg"
        />

        <div className="block p-4 w-full absolute bottom-0 bg-blue-400 text-white text-2xl truncate font-bold">
          {title.primaryTitle}
        </div>
      </li>
    </Link>
  );
}

export default TitleListItem;
