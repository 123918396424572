import { tsv } from 'd3-fetch';
import { useEffect, useState } from 'react';
import TitleList from '@/components/title-list';
import PageLayout from '@/components/page-layout';

function App() {
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    tsv('/titles/basic.tsv').then(res => {
      setTitles(res);
    });
  }, []);

  return (
    <>
      <PageLayout>
        <TitleList titles={titles} />
      </PageLayout>
    </>
  );
}

export default App;
