import PageLayout from '@/components/page-layout';

function Header() {
  return (
    <PageLayout>
      <div className="flex w-full my-8 mt-16">
        <h1 className="font-black text-4xl">Movie Tickets</h1>
      </div>
    </PageLayout>
  );
}

export default Header;
