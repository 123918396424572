import './index.css';
import ReactDOM from 'react-dom';
import App from '@/pages/app';
import SelectSeatPage from '@/pages/select-seat';
import SuccessPage from '@/pages/success';
import NotFound from '@/pages/not-found';
import Header from '@/components/header';
import PageShape from '@/components/page-shape';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

ReactDOM.render(
  <>
    <div className="fixed top-48 z-0">
      <PageShape />
      <PageShape flip={true} />
    </div>

    <div className="relative z-10">
      <Header />
      <Router>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/success" component={SuccessPage} />
          <Route exact path="/:id/seatmap" component={SelectSeatPage} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  </>,
  document.getElementById('root')
);
