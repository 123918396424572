import { Link } from 'react-router-dom';
import PageLayout from '@/components/page-layout';

function NotFound() {
  return (
    <>
      <PageLayout>
        <h1 className="font-bold text-2xl mb-2">
          Didn't find the page you were looking for
        </h1>
        <Link to="/">Click here to navigate back to movies </Link>
      </PageLayout>
    </>
  );
}

export default NotFound;
